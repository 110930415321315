@media print {
  @page {
    height: 279.4mm;
    margin: 14mm 6mm 11.8mm;
    padding: 0;
  }

  html, body {
    height: 279.4mm;
    margin: 0;
    padding: 0;
  }

  header {
    display: none !important;
  }

  #main {
    top: 0 !important;
  }

  .wrapper {
    margin: 0;
    height: auto !important;
    padding: 0 !important;
    display: block !important;
  }

  #title-bar, #filter-bar, #subnav-bar {
    display: none !important;
  }

  .list {
    margin: 0;
    padding: 0 !important;
    display: block !important;
  }

  .qr-code {
    float: left;
    position: relative;
    width: 80px !important;
    height: 25.4mm !important;
    margin: 0 8px !important;
    padding: 0 !important;
  }

  .code-screen {
    display: none !important;
  }

  .list.codes .code-print {
    margin-top: .2mm;
    height: 25mm !important;
    display: block !important;
  }

  .qr-label {
    box-sizing: border-box;
    position: absolute;
    overflow: hidden;
    width: 80px !important;
    height: 3.5mm !important;
    padding-top: 1px !important;
    font-size: 4px !important;
    line-height: 4px !important;
    bottom: .4mm !important;
  }

  .list.labels .qr-label {
    display: none !important;
  }

  .list.labels .print-label {
    text-align: center;
    overflow-wrap: break-word;
    border: 1px solid #000;
    justify-content: center;
    align-items: center;
    min-width: 0;
    height: 22mm !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    font-size: 8px !important;
    line-height: 8px !important;
    display: flex !important;
  }
}
/*# sourceMappingURL=index.b71703d5.css.map */
