/*
 * Styles for QR Code print page
 */

@media print {
  @page {
    height: 279.4mm;
    margin: 14mm 6mm 11.8mm 6mm;
    padding: 0;
  }
  html {
    height: 279.4mm;
    margin: 0;
    padding: 0;
  }
  body {
    margin: 0;
    padding: 0;
    height: 279.4mm;
  }
  header {
    display: none !important;
  }
  #main {
    top: 0 !important;
  }
  .wrapper {
    display: block !important;
    padding: 0px !important;
    height: auto !important;
    margin: 0;
  }
  #title-bar, #filter-bar, #subnav-bar {
    display: none !important;
  }
  .list {
    display: block !important;
    padding: 0 !important;
    margin: 0;
  }
  .qr-code {
    float: left;
    position: relative;
    width: 80px !important;
    height: 25.4mm !important;
    margin: 0px 8px 0mm 8px !important;
    padding: 0 !important;
  }
  .code-screen {
    display: none !important;
  }
  .list.codes .code-print {
    display: block !important;
    height: 25mm !important;
    margin-top: 0.2mm
  }
  .qr-label {
    position: absolute;
    font-size: 4px !important;
    line-height: 4px !important;
    padding-top: 1px !important;
    height: 3.5mm !important;
    bottom: 0.4mm !important;
    width: 80px !important;
    overflow: hidden;
    box-sizing: border-box;
  }

  .list.labels .qr-label {
    display: none !important;
  }

  .list.labels .print-label {
    display: flex !important;
    border: 1px solid black;
    height: 22mm !important;
    justify-content: center;
    align-items: center;
    font-size: 8px !important;
    line-height: 8px !important;
    text-align: center;
    padding-left: 5px !important;
    padding-right: 5px !important;
    overflow-wrap:break-word;
  }
  .list.labels .print-label {
    min-width: 0;
  }
}
